import styled from "styled-components";
import {
    primaryColor,
    infoColor } from "../../../config/colors";

export const Solicit = styled.div`
  margin-top: 30px;
  h2.title {
    margin-bottom: 20px;
    border-bottom: 3px solid ${infoColor};
  }
  .question {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .accordion-simple > .active {
    display: inline;
    color: blue;
    background-color: blue;
  }

  .bi {
    margin: 5px;
  }

  .accordion_faq .inactive{
    display: none;
  }

  .accordion_faq > div {
    margin-bottom: 20px;
    padding: 0px 15px;
    margin-left: 30px;
    border-radius: 5px;
    cursor: pointer;
    border-bottom: 3px solid ${infoColor};
  }

  .accordion_faq-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accordion_faq-heading .active{
    color: ${primaryColor};
  }

  .accordion_faq-heading h3{
    font-weight: 10;
    color: ${primaryColor};
  }

  .accordion_faq p {
    margin: 0px;
    padding-bottom: 30px;
    color: ${primaryColor};
    line-height: 1.4;
  }

  .result {
    margin-top: -20px;
    margin-bottom: 15px;
  }

  .bold {
    font-weight: bold;
  }

  .verticle {
    position: relative;
    right: 6%;
    top: -40px;
    font-size: 30px;
    color: ${primaryColor};
  }

  @media (max-width: 1200px) {
      img.img-faq {
        width: 1020px;
        max-width: 1020px;
      }
      .accordion_faq {
        width: 1000px;
        max-width: 1000px;
      }
    }

    @media (max-width: 1100px) {
      img.img-faq {
        width: 1000px;
        max-width: 1000px;
      }
      .accordion_faq {
        width: 950px;
        max-width: 950px;
      }
    }

    @media (max-width: 1050px) {
      img.img-faq {
        width: 950px;
        max-width: 950px;
      }
    }

    @media (max-width: 1000px) {
      img.img-faq {
        width: 900px;
        max-width: 900px;
      }
    }

    @media (max-width: 900px) {
      img.img-faq {
        margin-left: -100px;
      }
      .accordion_faq {
        width: 540px;
        max-width: 950px;
      }
    }

    @media (max-width: 400px) {
      img.img-faq {
        max-width: 450px;
      }
      .accordion_faq {
        width: 340px;
        max-width: 450px;
      }
    }

    @media (max-width: 500px) {
      img.img-faq {
        max-width: 500px;
      }
      .accordion_faq {
        width: 380px;
        max-width: 380px;
      }
    }
`;
