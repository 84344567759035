import React, {
  useState,
  useEffect,
  useContext
} from "react";

import {
  deleteSolicitacao,
  findAllSolicit,
  updateSolicitacao,
  findNextPage,
  findAllSolicitByPessoa,
  findPagePessoa,
  findAllByKeyWord,
  findByKeyWordPage,
  findByStatusPage,
  findAllByStatus,
} from "../../../services/axios";

import { AuthContext } from '../../../contexts/auth';
import { Solicit } from "./styled";
import Pagination from '@mui/material/Pagination';

export const Solicitacoes = () => {
  const [accordion, setActiveAccordion] = useState(-1);
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [filter, setFilter] = useState('');
  const [filterWord, setFilterWord] = useState('');
  const [numPages, setNumPages] = useState(0);
  const [current, setCurrent] = useState(0);
  const [keyWord, setKeyWord] = useState('');
  const [admin, setAdmin] = useState(false);
  const { user } = useContext(AuthContext);
  const { idFuncionario } = user;

  const toggleAccordion = (i) => {
    if (i === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(i);
  };

  const deletarSolicitacao = async (id) => {
    try {
      const confirm = window.confirm('Confirma que deseja excluir esta solicitação?');
      if (confirm) return await deleteSolicitacao(id);
    } catch (err) {
      console.error({
        errorName: 'Erro ao apagar a solicitação',
        error: err,
      });
    }
    return null;
  };

  const concluirSolicitacao = async (id) => {
    const confirmou = window.confirm('Confirma que deseja concluir esta solicitação?');
    if (confirmou) return await updateSolicitacao(id);
    return null;
  };

  const skipPage = async (page) => {
    try {
      let solicitacoes = '';
      let data = '';

      if (filter === 'self') {
        data = { start: page, idFuncionario };
        solicitacoes = await findPagePessoa(data);

      } else if (filter === 'pendentes' || filter === 'resolvidas') {
        let status;
        if (filter === 'pendentes') status = 5;
        if (filter === 'resolvidas') status = 4;
        data = { start: page, status };
        solicitacoes = await findByStatusPage(data);

      } else if (filterWord) {
        data = { start: page, keyWord };
        solicitacoes = await findByKeyWordPage(data);

      } else {
        solicitacoes = await findNextPage(page);
      }
      setCurrent(page);
      return setSolicitacoes(solicitacoes);
    } catch (err) {
      return console.error({
        NameError: 'Erro ao pular a página',
        error: err,
      });
    }
  };

  const findSolicit = async () => {
    try {
      let solicitacoes = '';
      if (filter === 'self') {
        let data = { start: current, idFuncionario };
        solicitacoes = await findPagePessoa(data);
      } else if (filter === 'pendentes' || filter === 'resolvidas') {
        let status;
        if (filter === 'pendentes') status = 5;
        if (filter === 'resolvidas') status = 4;
        let data = { start: current, status };
        solicitacoes = await findByStatusPage(data);
      } else if (filterWord) {
        let data = { start: current, keyWord };
        solicitacoes = await findByKeyWordPage(data);
      } else {
        solicitacoes = await findNextPage(current);
      }
      setSolicitacoes(solicitacoes);
    } catch (err) {
      console.error('Erro ao buscar as solicitações - home');
    }
  };

  const findNumPages = async () => {
    try {
      let chamados = '';
      if (filter === 'self') {
        chamados = await findAllSolicitByPessoa(idFuncionario);
      } else if (filter === 'pendentes' || filter === 'resolvidas') {
        let status;
        if (filter === 'pendentes') status = 5;
        if (filter === 'resolvidas') status = 4;
        chamados = await findAllByStatus(status);
      } else if (filterWord) {
        chamados = await findAllByKeyWord(keyWord);
      } else {
        chamados = await findAllSolicit();
      }
      const itemsIndex = [];
      let numPage = (Math.ceil(chamados.length)) / 5;

      let i = 0;
      while (numPage > 0) {
        i++
        itemsIndex.push(i);
        numPage--;
      }
      setNumPages(itemsIndex.length);

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (user.idCargo === 5 || user.idCargo === 6 || user.idCargo === 9 || user.idCargo === 10 || user.idCargo === 11) setAdmin(true);
    if (!keyWord) setFilterWord('');
    if (keyWord) setFilter('');
    if (filter) setFilterWord('');
    findNumPages();
    findSolicit();
  }, [solicitacoes, filter]);

  return (
    <Solicit id="solicit">
      <div className="accordion_faq mt-5">
        <h2 className="title">Solicitações de Suporte</h2>
        <p>Abaixo você confere todas as solicitações abertas</p>
        <form action="" className="filter">
          <p><select
            name="urgencia"
            id="urgencia"
            className="campo"
            defaultValue={filter}
            style={{ width: '100%' }}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="DEFAULT" name="DEFAULT">Sem filtros</option>
            <option value="self" name="self">Minhas solicitações</option>
            <option value="pendentes" name="pendentes">Solicitações pendentes</option>
            <option value="resolvidas" name="resolvidas">Solicitações resolvidas</option>
          </select>
          </p>

          <p style={{ marginTop: '-10px' }}>
            <input
              type="text"
              name="keyWord"
              id="keyWord"
              className="campo"
              placeholder="Buscar solicitação"
              value={keyWord}
              style={{ padding: '5px', width: '100%' }}
              onChange={(e) => {
                setKeyWord(e.target.value)
                setFilterWord(true);
              }}
            />
          </p>
        </form>
        {solicitacoes.map((item, i) => (
          <div key={item.idSolicitacao} className="total">
            <span id="callFunction" onClick={() => toggleAccordion(i)}>
              <div className="accordion_faq-heading">
                <div className={accordion === i ? "active question" : "question"}>
                  <h5 className="item1 ">{item.protocolo}</h5>
                  <h5 className="item2">Demanda: {item.demanda}</h5>
                  <h5 className="item4 ">Urgência: {item.urgencia}</h5>
                  <h5 className="item6 ">Data: {item.data}</h5>
                  <h5 className="item5" >Status:
                    <span id="status">
                      {item.status === 'Pendente' ?
                        <span className="statusPendente">{item.status}</span>
                        :
                        <span className="statusConcluido">{item.status}</span>
                      }
                    </span>
                  </h5>
                  {admin && (
                    <div>
                      <span onClick={() => deletarSolicitacao(item.idSolicitacao)}><i className="bi bi-trash-fill"></i></span>
                      <span onClick={() => concluirSolicitacao(item.idSolicitacao)}><i className="bi bi-check-circle"></i></span>
                    </div>
                  )}
                </div>
              </div>

              <div>
                {accordion === i ? (<>
                  <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                </>) : (<>
                  <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
              </div>
            </span>

            <div>
              <div className={accordion === i ? "active result" : "inactive result"}>
                <div><span className="bold">Nível de Urgência:</span> {item.urgencia}</div>
                <div><span className="bold">Demanda: </span>{item.demanda}</div>
                <div><span className="bold">Solicitante: </span>{item.nome}</div>
                <div><span className="bold">TG: </span>{item.tg}</div>
                <div><span className="bold">Data: </span>{item.data}</div>
                <div><span className="bold">Status:</span> {item.status}</div>
                <div><span className="bold">Protocolo: </span>{item.protocolo}</div>
                <div><span className="bold">Mensagem: </span>{item.mensagem}</div>
              </div>
            </div>

          </div>
        ))}

        <Pagination
          onChange={(event, page) => {
            const pageFormated = page - 1;
            console.log(page);
            skipPage((page === 1 ? pageFormated : (page - 1) * 5));
          }}
          count={numPages}
          className="pagination"
        />
      </div>

    </Solicit >
  );
}
