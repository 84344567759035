import React, { useContext, useState } from "react";
import { Update } from "./styled";
import { AuthContext } from "../../contexts/auth";
import { IMaskInput } from "react-imask";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import validarCPF from "./modules/ValidateCPF";
import { 
  updateCad
} from "../../services/axios";

export const UpdateCad = () => {
  const { user } = useContext(AuthContext);
  const [senha, setSenha] = useState('');
  const [repeatSenha, setRepeatSenha] = useState('');
  const [cpf, setCpf] = useState('');
  const [matricula, setMatricula] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [celular, setCelular] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const cpfFormatado = cpf.replace('.', '').replace('.', '').replace('-', '');
    const celularFormatado = celular.replace('(', '').replace(')', '').replace('-', '');

    const validate = () => {
      const cpf = validarCPF(cpfFormatado);

      if (!cpf.validate()) return { status: false, message: 'CPF inválido' };

      if (!senha || !cpf || !matricula || !dataNascimento) return { status: false, message: 'Campos obrigatórios: senha, cpf, matrícula e data de nascimento' };

      if (senha.length < 6) return { status: false, message: 'A senha precisa ter pelo menos 6 caracteres' };

      if (senha !== repeatSenha) return { status: false, message: 'As senhas precisam ser correspondentes' };

      if (senha === '123456') return { status: false, message: 'A senha deve ser diferente de 123456' };

      return { status: true, message: 'Informações corretas' };
    };

    const validado = validate();
    if (!validado.status) {
      return toast.error(validado.message);
    }

    const { email, idpessoa } = user;

    const dados = {
      idpessoa,
      email,
      senha,
      cpf: cpfFormatado,
      matricula,
      dataNascimento,
      celular: celularFormatado,
    };

    try {
      await updateCad(dados);
      toast.success('Dados atualizados com sucesso, faça login novamente para continuar utilizando o sistema.');
      return setTimeout(() => {
        navigate('/login');
      }, 5000);
    } catch (err) {
      return toast.error('Ocorreu um erro ao atualizar o cadastro.');
    }
  };

  return (
    <>
      <Update>
        <div className="efeito-vidro"></div>
        <div id="form">
          <h1>Atualização Cadastral</h1>
          <p>Seja bem vindo(a), {user.nome}!</p>
          <p>Para que você possa continuar utilizando o sistema, pedimos que preencha o formulário abaixo com algumas informações cadastrais.</p>
          <p>Agradecemos a colaboração!</p>

          <form>

            <p className="label"><label htmlFor="senha">Nova senha: </label></p>
            <input
              type="password"
              name="senha" id="senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              placeholder="Preencha com a sua nova senha"
            />

            {senha && (
              <div>
                <div>
                  {senha.length < 6 || senha === '123456' ? (
                    <div>
                      <p className="validate error">A senha deve ter pelo menos 6 caracteres.</p>
                      <p className="validate error">A senha deve ser diferente de 123456.</p>
                    </div>
                  ) : (
                    <p className="validate success">A senha atende aos requisitos.</p>)
                  }
                </div>
              </div>
            )}

            <p className="label"><label htmlFor="repeatSenha">Repita a senha: </label></p>
            <input
              type="password"
              name="repeatSenha" id="repeatSenha"
              value={repeatSenha}
              onChange={(e) => setRepeatSenha(e.target.value)}
              placeholder="Repita a sua nova senha"
            />

            {repeatSenha && (
              <div>
                <div>
                  {repeatSenha !== senha ? (
                    <p className="validate error">As senhas precisam ser iguais!</p>
                  ) : (
                    <p className="validate success">As senhas são correspondentes.</p>)
                  }

                </div>
              </div>
            )}

            <p className="label"><label htmlFor="cpf">CPF: </label></p>
            <IMaskInput
              name="cpf"
              id="cpf"
              mask="000.000.000-00"
              placeholder="Digite o seu CPF"
              value={cpf}
              onChange={(event) => setCpf(event.target.value)}
            />

            <p className="label"><label htmlFor="matricula">Número de matrícula de funcionário: </label></p>
            <IMaskInput
              name="matricula"
              id="matricula"
              mask="00000"
              value={matricula}
              onChange={(event) => setMatricula(event.target.value)}
              placeholder="Digite o número da sua matrícula"
            />

            <p className="label"><label htmlFor="dataNascimento">Data de Nascimento: </label></p>
            <IMaskInput
              id="dataNascimento"
              name="dataNascimento"
              mask="00/00/0000"
              value={dataNascimento}
              onChange={(event) => setDataNascimento(event.target.value)}
              placeholder="Digite o seu número de telefone"
            />

            <p className="label"><label htmlFor="telefone">Número de celular: </label></p>
            <IMaskInput
              id="telefone"
              name="telefone"
              mask="(00)00000-0000"
              value={celular}
              onChange={(event) => setCelular(event.target.value)}
              placeholder="Digite o seu número de celular"
            />
          </form>

          <button className="btn send" onClick={(event) => handleSubmit(event)}>Enviar</button>
        </div>
      </Update>
    </>
  );
};
