import styled from "styled-components";
import { primaryColor } from "../../config/colors";

export const Update = styled.section`
  .validate {
    margin-top: 10px;
  }

  .error {
    color: #ED4337;
  }

  .success {
    color: #5CB85C;
  }

  #form {
    background-color: ${primaryColor};
    color: #fff;
    position: absolute;
    left: 30%;
    right: 30%;
    z-index: 10;
    min-width: 600px;
    min-height: 600px;
    padding: 20px;
    box-shadow: 5px 5px 5px #1b1b1e;

    input {
      background-color: rgba(0, 0, 0, 0.3);
      border: none;
      padding: 5px;
      width: 90%;
      color: #fff;
      box-shadow: 5px 5px 5px #1b1b1e;
    }

    input[type="file"] {
      display: none;
    }

    label.upload {
      background-color: rgba(200,200,200,0.1);
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      padding: 5px 15px
    }

    .btn {
      width: 100px;
      background-color: rgba(200,200,200,0.1);
      margin-top: 10px;
      color: #fff;
      box-shadow: 5px 5px 5px #1b1b1e;
    }

    .send {
      margin-top: 15px;
    }

    .label {
      margin-bottom: -2px;
      margin-top: 10px;
    }
  }

`;
