import React, { createContext, useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { api, createSession } from '../services/axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const recoveredUser = localStorage.getItem('user');

    if (recoveredUser) {
      setUser(JSON.parse(recoveredUser));
    }

    setLoading(false);
  }, []);

  const login = async (email, senha) => {
    try {
      const response = await createSession(email, senha);
      const loggedUser = response.data.allInfoUser;

      if (loggedUser.senha === '123456') {
        navigate('/login');
      }
  
      localStorage.setItem('user', JSON.stringify(loggedUser));
  
      setUser(loggedUser);
      navigate('/');
      return 'Logado com sucesso';
    } catch (err) {
      return {error: 'Falha na autenticação'};
    }

  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    api.defaults.headers.Authorization = null;
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ authenticated: !!user, user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
