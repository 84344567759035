import styled from "styled-components";
import { primaryColor, seccondaryColor } from "../../config/colors";

export const Div = styled.div`
  display: flex;
  color: #fff;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
  position: fixed; top: 0; left: 0;
  overflow: hidden;

  main {
    height: 100vh;
    max-width: 35%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding: 20px;
    text-align: center;
    background-color: ${seccondaryColor};
    box-shadow: 10px 0 5px -2px #1b1b1e;
    
    p {
      font-size:13pt;
      width: 70%;
    }
  }  

  .background {
    width: 100%;
    background-color: ${primaryColor};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5 5px 5px #000;
  }

  .img-logo {
    width: 100%;
  }

  .button-login {
    padding: 13px;
    background-color: #284675;
    border: none;
  }
  .button-login:hover {
    background-color: #2d446d;
  }

  form {
    margin-top: 20px;
  }

  input {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    border: none;
    padding: 5px;
  }

  .input1 {
    margin-bottom: 10px;
  }
  .input2 {
    margin-bottom: 40px;
  }

  @media (max-width: 940px) {
    #login {
      min-width: 100%;
    }
  }

  @media (max-width: 700px) {
    #login {
      min-width: 40%;
      height: 100vh;
      min-width: 100vw;
      align-items: center;
      justify-content: center;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 20px;
      background-color: ${seccondaryColor};
      padding: 20px;
    }
  }
`;
