import styled from "styled-components";
import { primaryColor } from "../../config/colors";

export const SideBar = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 22%;
  background-color: ${primaryColor};
  margin-right: 5%;

  button {
    background-color: #Ff353a;
    margin-top: 20px;
    width: 20%;
    height: 40px;
    margin-bottom: 20px;
  }
  button:hover {
    background-color: #Ff354b ;
  }

  .cargo {
    font-weight: bold;
  }
  .img {
    width: 50px;
    margin-bottom: 20px;
  }

  .edit {
    margin-top: -10%;
    cursor: pointer;
  }

  .page {
    img {
      width: 250px;
    }
  }
  
  .selected {
    margin-left: 20%;
  }

`;
