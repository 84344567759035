import styled from "styled-components";
import {
  primaryColor,
  primaryLightColor,
} from "../../config/colors";

export const Container = styled.div`
  #main {
    max-width: 90%;
    color: ${primaryColor};
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    background-color: ${primaryLightColor};

    button.button {
      background-color: ${primaryColor};
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .intro {
      margin-top: 70px;
    }

    #header {
      .helpdesk {
        img {
          width: 250px;
        }
      }
      img {
        width: 50px;
        margin-left: 10px;
      }
    }
  
    .field {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
    }

    .campo {
      padding: 5px;
      background-color: #fff;
      border: none;
      border-radius: 10px;
      color: ${primaryColor};
    }

    select {
      height: 40px;
    }

    select option {
      margin: 400px;
      background-color: #000;
      color: #fff;
    }
  };
`;
