import React, {
  useState,
  useEffect,
  useContext
} from "react";

import {
  deleteIdeia,
  findAllIdeias,
  updateIdeia,
  findNextPageIdeia,
} from "../../../services/axios";

import { AuthContext } from '../../../contexts/auth';
import { Solicit } from "./styled";
import Pagination from '@mui/material/Pagination';

export const Solicitacoes = () => {
  const [accordion, setActiveAccordion] = useState(-1);
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [numPages, setNumPages] = useState(0);
  const [current, setCurrent] = useState(0);
  const { user } = useContext(AuthContext);

  const toggleAccordion = (i) => {
    if (i === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(i);
  };

  const deletarSolicitacao = async (id) => {
    try {
      const confirm = window.confirm('Confirma que deseja excluir esta solicitação?');
      if (confirm) return await deleteIdeia(id);
    } catch (err) {
      console.error({
        errorName: 'Erro ao apagar a solicitação',
        error: err,
      });
    }
    return null;
  };

  const concluirSolicitacao = async (id) => {
    const confirmou = window.confirm('Confirma que deseja concluir esta solicitação?');
    if (confirmou) return await updateIdeia(id);
    return null;
  };

  const skipPage = async (page) => {
    try {
      const solicitacoes = await findNextPageIdeia(page);
      setCurrent(page);
      return setSolicitacoes(solicitacoes);
    } catch (err) {
      return console.error({
        NameError: 'Erro ao pular a página',
        error: err,
      });
    }
  };

  useEffect(() => {
    const findSolicit = async () => {
      try {
        const solicitacoes = await findNextPageIdeia(current);
        setSolicitacoes(solicitacoes);
      } catch (err) {
        console.error('Erro ao buscar as solicitações - home');
      }
    };
    findSolicit();
  }, [solicitacoes]);

  useEffect(() => {
    const findNumPages = async () => {
      try {
        const chamados = await findAllIdeias();

        const itemsIndex = [];
        let numPage = (Math.ceil(chamados.length)) / 5;

        let i = 0;
        while (numPage > 0) {
          i++
          itemsIndex.push(i);
          numPage--;
        }
        setNumPages(itemsIndex.length);

      } catch (err) {
        console.log(err);
      }
    };

    findNumPages();
  }, [solicitacoes]);

  return (
    <Solicit>
      <div className="accordion_faq mt-5">
        <h2 className="title">Suas ideias</h2>
        <p>Abaixo você confere suas solicitações</p>
        {solicitacoes.map((item, i) => (
          <div key={item.idBancoIdeia} onClick={() => toggleAccordion(i)} className="total">
            <div className="accordion_faq-heading">
              <div className={accordion === i ? "active question" : "question"}>
                <h5 className="item1 ">Protocolo: {item.protocolo}</h5>
                <h5 className="item2">Título: {`${item.titulo.slice(0, 10)}...`}</h5>
                <h5 className="item4 ">Status: {item.status}</h5>
                <h5 className="item5 ">Data: {item.data}</h5>
                {user.nomeCargo === 'Assistente Web' && (
                  <div>
                    <span onClick={() => deletarSolicitacao(item.idBancoIdeia)}><i className="bi bi-trash-fill"></i></span>
                    <span onClick={() => concluirSolicitacao(item.idBancoIdeia)}><i className="bi bi-check-circle"></i></span>
                  </div>
                )}
              </div>
            </div>

            <div>
              {accordion === i ? (<>
                <span className="verticle"><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
            </div>

            <div>
              <div className={accordion === i ? "active result" : "inactive result"}>
                <div><span className="bold">Título: </span>{item.titulo}</div>
                <div><span className="bold">Data: </span>{item.data}</div>
                <div><span className="bold">Status:</span> {item.status}</div>
                <div><span className="bold">Protocolo: </span>{item.protocolo}</div>
                <div><span className="bold">Descrição: </span>{item.descricao}</div>
              </div>
            </div>

          </div>
        ))}

        <Pagination
          onChange={(event, page) => {
            const pageFormated = page - 1;
            console.log(page);
            skipPage((page === 1 ? pageFormated : (page - 1) * 5));
          }}
          count={numPages}
          className="pagination"
        />
      </div>

    </Solicit >
  );
}
