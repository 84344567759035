import styled from "styled-components";
import { errorColor, opacColor, primaryColor } from "../../../config/colors";

export const SectionUser = styled.section`
  text-align: center;
  margin-bottom: 5vh;

  .buttons button {
    border: none;
    color: #fff;
    width: auto;
    padding: 0 10px 0 10px;
  }

  .profile-picture {
    border-radius: 50%;
  }

  .profile-name, .cargo {
    font-weight: bold;
  }

  .view-profile, .view-profile:hover {
    width: auto;
    background-color: transparent;
    cursor: pointer;
  }

  .view-profile:hover {
    color: #c2c2c2;
  }

  .close {
    position: relative;
    top: -10px;
    left: 98%;
    cursor: pointer;
    background-color: ${errorColor};
    width: auto;
    padding: 5px;
  }

  .picture-info {
    border: 1px solid ${opacColor};
    border-radius: 100%;
    text-align: center;
    width: 20%;
    padding: 20px 0 10px 0;
    background: rgba( 0, 0, 0, 0.1 );
  }

  .d-flex {
    margin-bottom: 20px;
  }

  .btn {
    width: 100px;
    background-color: rgba(200,200,200,0.1);
    margin-top: 10px;
    color: #fff;
    box-shadow: 5px 5px 5px #1b1b1e;
  }

  .send {
    margin-top: 15px;
  }

  .label {
    margin-bottom: -2px;
    margin-top: 10px;
  }

  input {
      background-color: rgba(0, 0, 0, 0.3);
      border: none;
      padding: 5px;
      width: 90%;
      color: #fff;
      box-shadow: 5px 5px 5px #1b1b1e;
    }

  .bi-pencil-square {
    cursor: pointer;
    width: 10px;
    font-size: 14pt;
    margin-left: 10px;
  }

  .internInfo {
    margin-top: 5%;
  }

  .name {
    margin: 30px 0 0 5px;
  }

  .btn-save {
    margin: -10px 0 20px 3%;
    width: auto;
    border-radius: 0;
  }

  .edit-profile {
    position: relative;
    right: 30px;
  }

  .image-update {
    font-size: 12pt;
  }

  input[type="file"] {
      display: none;
    }

  label.upload {
    cursor: pointer
  }

  .allProfileInfo{
    background-color: ${primaryColor};
    color: #fff;
    position: absolute;
    left: 30%;
    right: 30%;
    z-index: 10;
    min-width: 600px;
    min-height: 400px;
    padding: 20px;
    box-shadow: 5px 5px 5px #1b1b1e;
    text-align: left;

    @media (max-width: 1030px) {
      min-width: 60%;
      left: 10%;
      right: 10%;
    }
  }

`;
