import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Container } from './styled';
import { Solicitacoes } from './Solicitacoes';
import bancoDeIdeias from './img/banco-de-ideias.png';

import {
  setIdeia
} from '../../services/axios';

import { AuthContext } from '../../contexts/auth';

export const BancoDeIdeias = () => {
  const { user } = useContext(AuthContext);

  // Obrigatórios
  const [descricao, setDescricao] = useState('');

  // Opcionais
  const [titulo, setTitulo] = useState('');

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (!titulo || !descricao) {
        return toast.error('Os campos Título e Mensagem são obrigatórios');
      }

      const { idFuncionario } = user;

      let solicitacao = {
        titulo,
        descricao,
        idFuncionario,
      };

      await setIdeia(solicitacao);
      toast.success('Solicitação enviada com sucesso');

      return clear();
    } catch (err) {
      console.error(err);
    }
  }

  const clear = () => {
    setDescricao('');
    setTitulo('');
  };

  return (
    <Container className="my-container">
      <div id="main">
        <div id="header">
          <span className="helpdesk"><img src={bancoDeIdeias} /></span>
        </div>

        <Solicitacoes />
        <div className="my-container intro">
          <h1>Registre suas ideias</h1>
          <p>Este sistema se destina a enviar sugestões para o banco de ideias.</p>
        </div>
        <form className="form">
          <div className="field">
            <label htmlFor="titulo">Título da ideia</label>
            <input
              type="text"
              name="titulo"
              id="titulo"
              className="campo"
              value={titulo}
              placeholder="Título da ideia"
              onChange={(e) => setTitulo(e.target.value)}
            />
          </div>

          <div className="field">
            <label htmlFor="descricao">Descreva sua ideia: </label>
            <textarea
              name="descricao"
              id="descricao"
              cols="30"
              rows="5"
              className="campo"
              placeholder='Digite a descrição'
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            >
            </textarea>
          </div>

          <button onClick={(e) => handleSubmit(e)} className="button">Enviar</button>
        </form>
      </div>
    </Container>
  );
};
