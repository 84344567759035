import React, { useContext } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import { Login } from '../components/Login/Login';
import { Home } from '../components/Home/Home';
import { BancoDeIdeias } from '../components/BancoDeIdeias/BancoDeIdeias';
import { Navbar } from '../components/NavBar';
import { UpdateCad } from '../components/UpdateCad';
import { AuthProvider, AuthContext } from '../contexts/auth';

const AppRoutes = () => {

  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);

    if (loading) {
      return <div className="loading">Carregando...</div>
    }

    if (!authenticated) {
      return <Navigate to='/login' />
    }

    return children;
  };

  return (
    <Router>
      <AuthProvider>
        <div className="d-flex">
          <Navbar />
          <Routes>
            <Route exact path='/' element={<Private><Home /></Private>} />

            <Route exact path='/login' element={<Login />} />

            <Route exact path='/banco-de-ideias' element={<Private><BancoDeIdeias /></Private>} />

            <Route exact path='/update-cad' element={<Private><UpdateCad /></Private>} />
          
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default AppRoutes;
