import React, { useState, useContext } from "react";
import { toast } from 'react-toastify';
import { AuthContext } from "../../contexts/auth";

import logoSuporte from './img/LogoSuporte.png'
import { Div } from "./styled";
import loginBaseDireita from './img/imagem_login.jpg';

export const Login = () => {
  const { login } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const mensagem = validate();
    if (mensagem.error) {
      return toast.error(mensagem.error);
    }
    const retorno = await login(email, senha);
    if (retorno.error) {
      return toast.error(retorno.error);
    }
  };

  function validate() {
    function validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    if (!email || !senha) {
      return { error: 'E-mail e senha são campos obrigatórios.' }
    }

    if (!validateEmail(email)) {
      return { error: 'E-mail inválido.' }
    }

    return { success: 'Logado com sucesso' }
  }

  return (
    <Div>
      <main className="w-100 m-auto" id="login">
        <img src={logoSuporte} alt="" />
        <br />
        <form action="">
          <div className="input1">
            <label htmlFor="email">Seu e-mail</label> <br />
            <input
              type="text"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="input2">
            <label htmlFor="senha">Sua senha</label> <br />
            <input
              type="password"
              name="senha"
              id="senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
          </div>

          <button className="button-login btn-lg text-light" type="submit" onClick={(event) => handleSubmit(event)}>Fazer login</button>
        </form>

        <p className="mt-5 mb-3 ">Desenvolvido por Núcleo de Educação à Distância UNIG &copy; 2022 - Todos os direitos reservados.</p>
      </main>

      <div className="shadow"></div>

      <div className="background">
        <img src={loginBaseDireita} className="img-fluid img-logo" alt="" />
      </div>

    </Div>
  );
}
