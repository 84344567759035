import React from 'react';
import AppRoutes from './routes/AppRoutes';
import GlobalStyles from './styles/GlobalStyles';
import { ToastContainer } from 'react-toastify';

export const App = () => {
  return (
    <div id="app">
      <GlobalStyles />
      <ToastContainer autoClose={3000} className="toast-container" />
      <AppRoutes />
    </div>
  );
}
