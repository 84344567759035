import React, { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import { SideBar } from "./styled";

import { Footer } from "./Footer";
import { User } from './User';
import { Menu } from "./Menu";

import logoSuporteAberto from './img/LogoSuporteAberto.png';
import logoSuporteRet from './img/sp.png'

export const Navbar = () => {
  const { user, logout, loading } = useContext(AuthContext);

  if (loading) {
    return <>Aguarde...</>;
  }

  if (!user) {
    return <></>;
  }

  return (
    <SideBar className="sidebar">
      <img src={logoSuporteAberto} alt="" className="logo-suporte-desk" />
      <img src={logoSuporteRet} alt="" className="logo-suporte-mobile" />

      <User user={user} logout={logout} />

      <Menu />

      <Footer />
    </SideBar>
  );
};
