import React, { useState } from "react";
import { Link } from "react-router-dom";
import helpDesk from '../img/LogoHelpDesk.png';
import bancoIdeias from '../img/bancoIdeias.png';
import helpdeskRet from '../img/helpdesk_ret.png';
import bancoIdeiaRet from '../img/banco_ideia_ret.png';

export const Menu = () => {
  const [page, setPage] = useState('helpDesk');

  const changePage = (value) => {
    setPage(value);
  };

  return (
    <section>
      <div className="menu-mobile">
        <Link to="/">
          <img src={helpdeskRet} alt="" />
        </Link>

        <Link to="banco-de-ideias">
          <img src={bancoIdeiaRet} alt="" />
        </Link>
      </div>

      <div className="menu-desktop">
        {page === 'helpDesk' ?
          <div>
            <Link to="/">
              <div className="selected page" onClick={() => changePage('helpDesk')}>
                <img src={helpDesk} alt="" />
              </div>
            </Link>

            <Link to="/banco-de-ideias">
              <div className="seccondary page" onClick={() => changePage('bancoIdeias')}>
                <img src={bancoIdeias} alt="" />
              </div>
            </Link>
          </div>

          :

          <div>
            <Link to="/">
              <div className="seccondary page" onClick={() => changePage('helpDesk')}>
                <img src={helpDesk} alt="" />
              </div>
            </Link>
            <Link to="/banco-de-ideias">
              <div className="selected page" onClick={() => changePage('bancoIdeias')}>
                <img src={bancoIdeias} alt="" />
              </div>
            </Link>
          </div>
        }
      </div>
    </section>
  );
};
