import React, { useState } from "react";
import { SectionUser } from "./styled";
// import { toast } from "react-toastify";

// import {
//   uploadImage,
//   findImage,
// } from "../../../services/axios";

export const User = ({ user, logout }) => {
  const [controlador, setControlador] = useState(null);
  //const [image, setImage] = useState('');
  // const [endImg, setEndImg] = useState('');

  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  // useEffect(() => {
  //   const buscarImagem = async () => {
  //     const [imagem] = await findImage(user.idpessoa);
  //     console.log(image);

  //     if (imagem) {
  //       const { nome } = imagem;
  //       return setEndImg(`http://localhost:3001/files/users/${nome}`);
  //     }
  //     return setEndImg('./icone_usuario.png');
  //   };
  //   buscarImagem();
  // }, [image]);

  // const updateImage = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const formData = new FormData();
  //     formData.append('image', image);
  //     const { matricula, idpessoa } = user;

  //     const response = await uploadImage(matricula, formData, idpessoa);
  //     console.log(response);
  //     return toast.success('Imagem atualizada com sucesso');
  //   } catch (e) {
  //     console.log(e);
  //     return toast.error('Ocorreu um erro ao atualizar a sua imagem.');
  //   }
  // };

  const viewInfoProfile = (event) => {
    event.preventDefault();
    if (controlador) return setControlador(false);
    return setControlador(true);
  };

  return (
    <SectionUser className="user" style={style}>
      {controlador && (
        <div>
          <div className="efeito-vidro"></div>
          <div className="allProfileInfo">
            <i className="bi bi-x-lg close" onClick={(event) => viewInfoProfile(event)}></i>
            <div className="internInfo">

              {/* <div className="d-flex">
                <div className="">
                  {image ? <img src={URL.createObjectURL(image)} alt="imagem de perfil" className="profile-picture" width={100} height={100} /> : <img src={endImg} alt="imagem de perfil" width={100} height={100} className="profile-picture"/>}
                </div>
                <p><label htmlFor="imagem" className="upload edit-profile"><i className="bi bi-pencil-square image-update"></i></label></p>
                <input type="file" name="image" id="imagem" onChange={(e) => setImage(e.target.files[0])} />
                
              </div>

              {image && (
                <button
                  onClick={(e) => updateImage(e)}
                  className="btn btn-save"
                >Salvar</button>
              )} */}
              <h2 className="">{user.nome}</h2>
              <div><span className="bold">E-mail: </span>{user.email}</div>
              <div><span className="bold">Cargo: </span>{user.nomeCargo}</div>
              <div><span className="bold">Matrícula: </span>{user.matricula}</div>
            </div>
          </div>
        </div>
      )}
      <div className="user">
        {/* <div className="profile-picture">
          <img src={endImg} alt="imagem de perfil" width={100} height={100} className="profile-picture" />
        </div> */}
        <div className="profile-info">
          <div><span className="profile-name">Seja bem-vindo (a), </span> {user.nome}!</div>
          <div><span className="cargo">Cargo:</span> {user.nomeCargo}</div>
        </div>
      </div>
      <div className="buttons">
        <span
          style={{ marginRight: '10px', cursor: 'pointer' }}
        >
          <i
            className="bi bi-gear"
            onClick={(event) => viewInfoProfile(event)}>
          </i>
        </span>
        <button onClick={logout}>Sair</button>
      </div>
    </SectionUser>
  );
};
