import { createGlobalStyle } from "styled-components";
import {
  primaryLightColor,
} from '../config/colors';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
  }

  body {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: ${primaryLightColor};
  }

  button.button {
    border: none;
    padding: 10px; 
    color: #fff;
  }

  .bold {
    font-weight: bold;
  }

  .efeito-vidro {
    background: rgba( 0, 0, 0, 0.35 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 13.5px );
    -webkit-backdrop-filter: blur( 13.5px );
    border: 1px solid rgba( 0, 0, 0, 0.18 );
    min-height: 100vh;
    position: fixed;
    z-index: 2;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }


  div.my-container {
    width: 1004px;
    margin: 0 auto;
    margin-top: 40px;
    @media (max-width: 1030px) {
      margin-top: 200px;
      width: 900px;
    }
    @media (max-width: 1000px) {
      width: 850px;
    }
    @media (max-width: 900px) {
      width: 800px;
    }
    @media (max-width: 850px) {
      width: 700px;
    }
    @media (max-width: 800px) {
      width: 600px;
    }
    @media (max-width: 650px) {
      width: 500px;
    }
    @media (max-width: 500px) {
      width: 400px;
    }
    @media (max-width: 450px) {
      width: 300px;
    }
    @media (max-width: 400px) {
      width: 250px;
    }
    @media (max-width: 350) {
      width: 150px;
    }
    @media (max-width: 300) {
      width: 100px;
    }
  }

  .logo-suporte-mobile {
    display: none;
  }
  .menu-mobile {
    display: none;
  }

  @media (max-width: 1030px) {
      .sidebar {
        display: flex;
        flex-direction: row;
        position: absolute;
        width: 98vw;
        height: 100px;
      }
      .logo-suporte-mobile {
        display: block;
      }
      .logo-suporte-desk {
        display: none;
      }
      .footer, .user {
        display: none;
      }
      .buttons {
        position: absolute;
        right: 10px;
        top: 1px;
      }
      .menu-desktop {
        display: none;
      }
      .menu-mobile {
        display: block;
        margin-left: 20px;
        img {
          width: 50px;
        }
      }
      .allProfileInfo {
        left: 10px;
      }
      #header {
        width: 110%;
      }
      #solicit {
        max-width: 2px;
      }
      .question {
        flex-direction: column;
      }
      .verticle {
        left: 50%;
        top: -2000px;
      }
    }
    @media (max-width: 450px) {
      .helpdesk img {
        position: relative;
        left: -80px;
      }
    }

`;
