import axios from 'axios';

export const api = axios.create({
  baseURL: 'https://api.portaldoprofessor.ead.e-unig.com.br',
});

export const createSession = async (email, senha) => {
  return api.post('/tokens', { email, senha });
};

export const setSolicitacao = async (solicitacao) => {
  return api.post('/chamados', solicitacao);
};

export const findAllSolicit = async () => {
  const solicitacoes = await api.get('/chamados');
  return solicitacoes.data;
};

export const findAllSolicitByPessoa = async (idFuncionario) => {
  const solicitacoes = await api.get(`/chamados/${idFuncionario}`);
  return solicitacoes.data;
};

export const findAllByStatus = async (status) => {
  const solicitacoes = await api.post(`/chamados/findAllByStatus`, { status });
  return solicitacoes.data;
};

export const findByStatusPage = async (data) => {
  const chamados = await api.post('/chamados/findByStatusPage', data);
  return chamados.data;
};

export const findDemandas = async () => {
  return api.get('/demandas');
};

export const findUrgencias = async () => {
  return api.get('/urgencias');
};

export const findCursos = async () => {
  return api.get('/cursos');
};

export const findEntradasByCurso = async (idCurso) => {
  return api.post('/entradas', { idCurso });
};

export const findPeriodoByCurso = async (idCurso) => {
  console.log(idCurso);
  return api.post('/periodos', { idCurso });
};

export const findIdCursoEntrada = async (ids) => {
  return api.post('/curso-entrada', ids);
};

export const deleteSolicitacao = async (id) => {
  return await api.delete(`/chamados/${id}`);
};

export const updateSolicitacao = async (id) => {
  return await api.put(`/chamados/${id}`);
};

export const findNextPage = async (offset) => {
  const chamados = await api.get(`/chamados/findPage/${offset}`);
  return chamados.data;
};

export const findPagePessoa = async (data) => {
  const chamados = await api.post(`/chamados/findPagePessoa`, data);
  return chamados.data;
};

export const findAllByKeyWord = async (keyWord) => {
  const chamados = await api.post('/chamados/findAllByKeyWord', { keyWord });
  return chamados.data;
};

export const findByKeyWordPage = async (data) => {
  const chamados = await api.post('/chamados/findByKeyWordPage', data);
  return chamados.data;
};

export const setIdeia = async (solicitacao) => {
  return api.post('/ideias', solicitacao);
};

export const findAllIdeias = async () => {
  const ideias = await api.get('/ideias');
  return ideias.data;
};

export const findNextPageIdeia = async (offset) => {
  const ideias = await api.get(`/ideias/findPage/${offset}`);
  return ideias.data;
};

export const updateIdeia = (id) => {
  return api.put(`/ideias/${id}`);
};

export const deleteIdeia = (id) => {
  return api.delete(`/ideias/${id}`);
};

export const viaCEP = async (cep) => {
  const endereco = await api.get(`https://viacep.com.br/ws/${cep}/json`);
  return endereco.data;
}; 

export const updateCad = async (dados) => {
  const updated = await api.post('/update-cad', dados);
  return updated;
}

export const updateAdress = async (dados) => {
  const updated = await api.put('/update-cad', dados);
  return updated;
}

export const uploadImage = async (matricula, formData, idpessoa) => {
  return api.post(`update-cad/update-image/${matricula}&${idpessoa}`, formData);
};

export const findImage = async (id) => {
  const imagem = await api.get(`update-cad/${id}`);
  return imagem.data;
};
