import React from "react";
import logo from '../../../img/logo-branco.png';

export const Footer = () => {
  const style = {
    textAlign: 'center', width: '80%',
    margin: '0 auto',
    marginTop: '50vh',
  };
  return (
    <footer className="footer" style={style}>
      <img src={logo} alt="" style={{ width: '150px' }}/>
      <div className="mt-4">
        Desenvolvido por Núcleo de Educação a Distância UNIG &copy; 2022 <br />
        Todos os direitos reservados.
      </div>
    </footer>
  );
};
