import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Container } from './styled';
import { Solicitacoes } from './Solicitacoes';
import helpDeskImage from './img/help-desk.png'
import {
  findUrgencias,
  findCursos,
  setSolicitacao,
} from '../../services/axios';
import { UpdateCad } from '../UpdateCad';
import { AuthContext } from '../../contexts/auth';

export const Home = () => {
  const { user } = useContext(AuthContext);
  const [urgencias, setUrgencias] = useState([]);
  const [cursos, setCursos] = useState([]);

  // Obrigatórios
  const [idCurso, setIdCurso] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [idUrgencia, setIdUrgencia] = useState('');
  const [idDemanda, setIdDemanda] = useState('');

  // Opcionais
  const [tg, setTg] = useState('');
  const [idEntrada, setIdEntrada] = useState('');

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (!idUrgencia || !idDemanda || !idCurso || !mensagem) {
        return toast.error('Os campos Nível de Urgência, Demanda, Curso e mensagem são obrigatórios.');
      }
      const { idFuncionario, idCargo } = await user;

      let solicitacao = {
        tg,
        idFuncionario,
        idDemanda,
        idCargo,
        idEntrada,
        mensagem,
        idUrgencia,
      };

      await setSolicitacao(solicitacao);
      toast.success('Solicitação enviada com sucesso');
      return clear();
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    const buscarUrgencias = async () => {
      try {
        const response = await findUrgencias();
        setUrgencias(response.data);
      } catch (err) {
        console.log('Erro ao buscar as urgências - front');
      }
    };

    const buscarCursos = async () => {
      try {
        const response = await findCursos();
        setCursos(response.data);
      } catch (err) {
        console.log('Erro ao buscar os cursos - front');
      }
    };

    buscarCursos();
    buscarUrgencias();
  }, [idCurso]);

  const clear = () => {
    setMensagem('');
    setTg('');
  };


  const entradas = [
    {
      idEntrada: 1,
      numeroEntrada: 1,
    },
    {
      idEntrada: 2,
      numeroEntrada: 2,
    },
    {
      idEntrada: 3,
      numeroEntrada: 3,
    },
    {
      idEntrada: 4,
      numeroEntrada: 4,
    },
  ];

  const demandas = [
    {
      idDemanda: 1,
      nome: 'Card',
    },
    {
      idDemanda: 2,
      nome: 'Fórum',
    },
    {
      idDemanda: 3,
      nome: 'Sophia',
    },
    {
      idDemanda: 4,
      nome: 'UA',
    },
    {
      idDemanda: 5,
      nome: 'Mensagem',
    },
    {
      idDemanda: 6,
      nome: 'Nota',
    },
    {
      idDemanda: 7,
      nome: 'Conteúdo',
    },
    {
      idDemanda: 8,
      nome: 'Vínculo',
    },
    {
      idDemanda: 9,
      nome: 'Outros',
    },
  ];

  return (
    <Container className="my-container">
      {user.senha === '123456' && (
        <UpdateCad />
      )}
      <div id="main">
        <div id="header">
          <span className="helpdesk"><img src={helpDeskImage} /></span>
        </div>

        <Solicitacoes />
        <div className="my-container intro">
          <h1>Solicitações de Suporte</h1>
          <p>Este sistema se destina a abrir solicitações para o suporte NEAD.</p>
        </div>
        <form className="form">
          <div className="field">
            <label htmlFor="urgencia">Selecione a urgência da sua solicitação</label>
            <select
              name="urgencia"
              id="urgencia"
              className="campo"
              defaultValue={'DEFAULT'}
              onChange={(e) => setIdUrgencia(e.target.value)}
            >
              <option value="DEFAULT" disabled>Selecione a urgência</option>
              {urgencias.map(item => (
                <option value={item.idUrgencia} key={item.idUrgencia}>{item.nome}</option>
              ))}
            </select>
          </div>

          <div className="field">
            <label htmlFor="curso">Escolha o curso: </label>
            <select
              name="curso"
              id="curso"
              className="campo"
              onChange={(e) => setIdCurso(e.target.value)}
              defaultValue={'DEFAULT'}
            >
              <option value="DEFAULT" disabled>Selecione o curso</option>
              {cursos.map(item => (
                <option value={item.idCurso} key={item.idCurso}>{item.nome} - {item.grauAcademico}</option>
              ))}
            </select>
          </div>

          <div className="field">
            <label htmlFor="demanda">Selecione a demanda da sua solicitação</label>
            <select
              name="demanda"
              id="demanda"
              className="campo"
              onChange={(e) => setIdDemanda(e.target.value)}
              defaultValue={'DEFAULT'}
            >
              <option value="DEFAULT" disabled>Selecione a demanda</option>
              {demandas.map(item => (
                <option value={item.idDemanda} key={item.idDemanda}>{item.nome}</option>
              ))}
            </select>
          </div>

          {idCurso && (
            <>
              <div className="field">
                <label htmlFor="entrada">Selecione a entrada: </label>
                <select
                  name="entrada"
                  id="entrada"
                  className="campo"
                  onChange={(e) => setIdEntrada(e.target.value)}
                  defaultValue={'DEFAULT'}
                >
                  <option value="DEFAULT" disabled>Selecione a entrada</option>
                  {entradas.map(item => (
                    <option value={item.idEntrada} key={item.idEntrada}>{item.numeroEntrada}</option>
                  ))}
                </select>
              </div>
            </>
          )}

          <div className="field">
            <label htmlFor="tg">Digite o nome do TG:</label>
            <input
              type="text"
              name="tg"
              id="tg"
              className="campo"
              value={tg}
              placeholder="Nome do Tema Gerador"
              onChange={(e) => setTg(e.target.value)}
            />
          </div>

          <div className="field">
            <label htmlFor="mensagem">Digite a sua mensagem</label>
            <textarea
              name="mensagem"
              id="mensagem"
              cols="30"
              rows="5"
              className="campo"
              placeholder='Digite a descrição'
              value={mensagem}
              onChange={(e) => setMensagem(e.target.value)}
            >
            </textarea>
          </div>

          <button onClick={(e) => handleSubmit(e)} className="button">Enviar</button>
        </form>
      </div>
    </Container>
  );
};
